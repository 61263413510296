var markerPosition = {
	// norte
	am : {
		x :  172,
		y : 153
	},
	ap : {
		x : 415,
		y : 38
	},
	ac : {
		x :  67,
		y : 274
	},
	rr : {
		x :  229,
		y : 38
	},
	ro : {
		x :  201,
		y : 292
	},
	pa : {
		x :  390,
		y : 163
	},
	to : {
		x :  487,
		y : 277
	},
	// nordeste
	se : {
		x : 716,
		y : 319
	},
	ce : {
		x : 660,
		y : 184
	},
	al : {
		x : 739,
		y : 295
	},
	pb : {
		x : 755,
		y : 226
	},
	pe : {
		x : 755,
		y : 255
	},
	rn : {
		x : 726,
		y : 181
	},
	ma : {
		x : 549,
		y : 176
	},
	pi : {
		x : 587,
		y : 238
	},
	ba : {
		x : 611,
		y: 327
	},
	mt : {
		x : 336,
		y: 327
	},
	ms : {
		x : 357,
		y : 469
	},
	go : {
		x : 442,
		y : 412
	},
	df : {
		x : 486,
		y : 356
	},
	mg : {
		x : 555,
		y : 444
	},
	es : {
		x : 637,
		y : 473
	},
	mg : {
		x : 555,
		y : 444
	},
	rj : {
		x : 599,
		y : 539
	},
	sp : {
		x : 468,
		y : 506
	},
	pr : {
		x : 418,
		y : 561
	},
	sc : {
		x : 445,
		y : 614
	},
	rs : {
		x : 388,
		y : 655
	}
};

$('.mapa-brasil').each(function(){
	var mapa 	= $(this);
	var g 		= $(this).find('g');
	var target 	= $(this).data('target');


	g.each(function(){
		var id = $(this).attr('id');

		if(markerPosition[id]) {

			var pin = '<use xlink:href="#marker" x="'+markerPosition[id].x+'" y="'+markerPosition[id].y+'" width="39" height="51"/>';

			if($(this).data('pin')){
				this.insertAdjacentHTML('beforeend', pin);
			}
		}else {
			console.log(id)
		}

		$(this).click(function() {
			var value  = $(this).data('value') || $(this).attr('id');

			$(target).val(value).change();
		});
	});


	$(target).change(function() {
		var value = $(this).val();

		g.each(function(){
			if(this.id == value) {
				$(this).attr('class', 'active');
			}else {
				$(this).attr('class', '');
			}
		})
	});
});
